export const contactsData = {
  email: "rasikkunwar31@gmail.com",
  phone: "+1 (641) 819-8169",
  address: "840 NW 98th Ave, Plantation, FL 33324, USA",
  github: "https://github.com/rasikkunwar",
  linkedIn: "https://www.linkedin.com/in/rasik-kunwar/",
  medium: "https://medium.com/@rasikkunwar31",
  stackOverflow: "https://stackoverflow.com/users/6773456/rasik",
  devUsername: "rasikkunwar",
};
