import { Container } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import education from "../../../assets/lottie/education.json";
import { ThemeContext } from "../../../contexts/theme-context";
import { getEducationData } from "../../../data/educationData";
//import { educationData } from '../../../data/educationData';
import AnimationLottie from "../../helper/animation-lottie";
import EducationCard from "./education-card";
import "./education.css";

function Education() {
  const [educationData, setEducationData] = useState([]);
  useEffect(() => {
    getEducationData().then((data) =>
      setEducationData(
        data.data.body.sort((a, b) => b.startYear?.S - a.startYear?.S)
      )
    );
  }, []);
  const { theme } = useContext(ThemeContext);

  return (
    <div style={{ backgroundColor: theme.secondary }}>
      <Container className="education" id="resume">
        <div className="education-body">
          <div className="education-image">
            <AnimationLottie animationPath={education} />
          </div>
          <div className="education-description">
            <h1 style={{ color: theme.primary }}>Education</h1>
            {educationData.map((edu) => (
              <EducationCard
                key={edu.id?.S}
                id={edu.id?.S}
                institution={edu.institution?.S}
                course={edu.course?.S}
                startYear={edu.startYear?.S}
                endYear={edu.endYear?.S}
              />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Education;
