export const skillsData = [
  "HTML",
  "Javascript",
  "TypeScript",
  "CSS",
  "Bootstrap",
  "PHP",
  "Laravel",
  "Node",
  "Nest JS",
  "React",
  "Next JS",
  "Vue",
  "Nuxt JS",
  "MongoDB",
  "MySQL",
  "AWS",
  "Git",
];
