import { IconButton, Snackbar, SnackbarContent } from "@mui/material";
import React, { useContext, useRef } from "react";
import { AiOutlineSend } from "react-icons/ai";
import {
  FaGithub,
  FaLinkedinIn,
  FaMediumM,
  FaStackOverflow,
  FaTwitter,
} from "react-icons/fa";
import { FiAtSign, FiPhone } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { ThemeContext } from "../../../contexts/theme-context";
import { contactsData } from "../../../data/contactsData";
import "./contacts.css";

const ContactUI = ({
  open,
  success,
  errMsg,
  handleClose,
  classes,
  handleContactForm,
  form,
  isLoading,
}) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      className="contacts"
      id="contacts"
      style={{ backgroundColor: theme.secondary }}
    >
      <div className="contacts--container">
        <h1 style={{ color: theme.primary }}>Contacts</h1>
        <p className="required-message-container">
          All fields marked with<span className="required">*</span> are
          required.
        </p>
        <div className="contacts-body">
          <div className="contacts-form">
            <form onSubmit={(e) => handleContactForm(e)} ref={form}>
              <div className="input-container">
                <label htmlFor="Name" className={classes.label}>
                  Name <span className="required">*</span>
                </label>
                <input
                  placeholder="Enter your name"
                  type="text"
                  name="user_name"
                  className={`form-input ${classes.input}`}
                  required
                />
              </div>
              <div className="input-container">
                <label htmlFor="Email" className={classes.label}>
                  Email <span className="required">*</span>
                </label>
                <input
                  placeholder="your@mail.com"
                  type="email"
                  name="user_email"
                  className={`form-input ${classes.input}`}
                  required
                />
              </div>
              <div className="input-container">
                <label htmlFor="Phone" className={classes.label}>
                  Phone Number
                </label>
                <input
                  placeholder="Enter your phone number"
                  type="number"
                  name="user_phone"
                  className={`form-input ${classes.input}`}
                />
              </div>
              <div className="input-container">
                <label htmlFor="Message Title" className={classes.label}>
                  Message Title <span className="required">*</span>
                </label>
                <input
                  placeholder="Enter your message title"
                  type="text"
                  name="message_title"
                  className={`form-input ${classes.input}`}
                  required
                />
              </div>
              <div className="input-container">
                <label htmlFor="Message" className={classes.label}>
                  Message <span className="required">*</span>
                </label>
                <textarea
                  placeholder="Type your message...."
                  type="text"
                  name="message"
                  className={`form-message ${classes.message}`}
                  required
                />
              </div>
              {success && (
                <div className="message-container">
                  <p>
                    Thank you for contacting me! I will get back to you soon.
                  </p>{" "}
                </div>
              )}
              {isLoading ? "sdsd" : "assd"}

              <div className="submit-btn">
                <button type="submit" className={classes.submitBtn}>
                  <p>{"Send"}</p>
                  <div className="submit-icon">
                    <AiOutlineSend
                      className="send-icon"
                      style={{
                        animation: !isLoading
                          ? "initial"
                          : "fly 0.8s linear both",
                        position: isLoading ? "absolute" : "initial",
                      }}
                    />
                  </div>
                </button>
              </div>
            </form>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={open}
              autoHideDuration={4000}
              onClose={handleClose}
            >
              <SnackbarContent
                action={
                  <React.Fragment>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={handleClose}
                    >
                      <IoClose fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                }
                style={{
                  backgroundColor: theme.primary,
                  color: theme.secondary,
                  fontFamily: "var(--primaryFont)",
                }}
                message={errMsg}
              />
            </Snackbar>
          </div>

          <div className="contacts-details">
            <a
              href={`mailto:${contactsData.email}`}
              className="personal-details"
            >
              <div className={classes.detailsIcon}>
                <FiAtSign />
              </div>
              <p style={{ color: theme.tertiary }}>{contactsData.email}</p>
            </a>
            <a href={`tel:${contactsData.phone}`} className="personal-details">
              <div className={classes.detailsIcon}>
                <FiPhone />
              </div>
              <p style={{ color: theme.tertiary }}>{contactsData.phone}</p>
            </a>
            <div className="personal-details">
              <div className={classes.detailsIcon}>
                <HiOutlineLocationMarker />
              </div>
              <p style={{ color: theme.tertiary }}>{contactsData.address}</p>
            </div>

            <div className="socialmedia-icons">
              {contactsData.twitter && (
                <a
                  href={contactsData.twitter}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.socialIcon}
                >
                  <FaTwitter aria-label="Twitter" />
                </a>
              )}
              {contactsData.github && (
                <a
                  href={contactsData.github}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.socialIcon}
                >
                  <FaGithub aria-label="GitHub" />
                </a>
              )}
              {contactsData.linkedIn && (
                <a
                  href={contactsData.linkedIn}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.socialIcon}
                >
                  <FaLinkedinIn aria-label="LinkedIn" />
                </a>
              )}

              {contactsData.medium && (
                <a
                  href={contactsData.medium}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.socialIcon}
                >
                  <FaMediumM aria-label="Medium" />
                </a>
              )}

              {contactsData.stackOverflow && (
                <a
                  href={contactsData.stackOverflow}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.socialIcon}
                >
                  <FaStackOverflow aria-label="Stack Overflow" />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <img src={theme.contactsimg} alt="contacts" className="contacts--img" />
    </div>
  );
};

export default ContactUI;
