import eight from "../assets/png/nepaliport_logo.png";
import seven from "../assets/png/health-at-home.png";
import six from "../assets/svg/projects/ambition-guru.svg";
import five from "../assets/png//katerva.png";

export const projectsData = [
  {
    id: 1,
    projectName: "NepaliPort",
    projectDesc:
      "This is the application of Nepal Government's Department of Immigration for all the use cases of visa system of Nepal.",
    tags: ["PHP", "Laravel", "HTML", "CSS", "JavaScript", "Vue"],
    code: "",
    demo: "https://nepaliport.immigration.gov.np/",
    image: eight,
  },
  {
    id: 2,
    projectName: "Health At Home",
    projectDesc:
      "Nepal's first home-based service providing quality health care solutions at your doorstep with Doctors, Nurses, Travel Support, Vaccinations, Pharmacy, Counselling and Therapy",
    tags: ["PHP", "Laravel", "HTML", "CSS", "JavaScript"],
    code: "",
    demo: "https://healthathome.com.np/",
    image: seven,
  },
  {
    id: 3,
    projectName: "Ambition Guru",
    projectDesc:
      "Ambition Guru Nepal Pvt. Ltd. enhances your learning experience with proper use of information technology.",
    tags: ["Html", "Css", "JavaScript", "VueJs", "Quasar", "PHP", "Laravel"],
    code: "",
    demo: "https://ambition.guru/",
    image: six,
  },
  {
    id: 4,
    projectName: "Katerva",
    projectDesc:
      "Our purpose is to support the ambitious teams building these disruptive solutions.",
    tags: ["Html", "Css", "JavaScript", "VueJs", "PHP", "Laravel"],
    code: "",
    demo: "https://katerva.net/",
    image: five,
  },
  {
    id: 5,
    projectName: "Music Player",
    projectDesc:
      "Designed and developed an asynchronous application that lets users view a song list, make playlists, and play, pause, forward, backward, seek, shuffle, and repeat any song.",
    tags: ["Html", "Css", "Bootstrap", "TypeScript"],
    code: "https://github.com/rasikkunwar/ras-music-app",
    demo: "",
    image: "",
  },
];
