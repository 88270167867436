import emailjs from "@emailjs/browser";
import { makeStyles } from "@mui/styles";
import React, { useContext, useRef, useState } from "react";
import isEmail from "validator/lib/isEmail";
import { ThemeContext } from "../../contexts/theme-context";
import ContactUI from "../core-ui/contacts/contacts-ui";
import axios from "axios";

const Contacts = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const form = useRef();
  const { theme } = useContext(ThemeContext);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const useStyles = makeStyles((t) => ({
    input: {
      border: `2px solid ${theme.buttonColor}`,
      backgroundColor: `${theme.secondary}`,
      color: `${theme.tertiary}`,
      fontFamily: "var(--primaryFont)",
      fontWeight: 500,
      transition: "border 0.2s ease-in-out",
      "&:focus": {
        border: `2px solid ${theme.primary}`,
      },
    },
    message: {
      border: `2px solid ${theme.buttonColor}`,
      backgroundColor: `${theme.secondary}`,
      color: `${theme.tertiary}`,
      fontFamily: "var(--primaryFont)",
      fontWeight: 500,
      transition: "border 0.2s ease-in-out",
      "&:focus": {
        border: `2px solid ${theme.primary}`,
      },
    },
    label: {
      backgroundColor: `${theme.secondary}`,
      color: `${theme.tertiary}`,
      fontFamily: "var(--primaryFont)",
      fontWeight: 600,
      fontSize: "0.9rem",
      padding: "0 5px",
      transform: "translate(25px,50%)",
      display: "inline-flex",
    },
    socialIcon: {
      width: "45px",
      height: "45px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "21px",
      backgroundColor: theme.buttonColor,
      color: theme.secondary,
      transition: "250ms ease-in-out",
      "&:hover": {
        transform: "scale(1.1)",
        color: theme.secondary,
        backgroundColor: theme.primary,
      },
    },
    detailsIcon: {
      backgroundColor: theme.buttonColor,
      color: theme.secondary,
      borderRadius: "50%",
      width: "45px",
      height: "45px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "23px",
      transition: "250ms ease-in-out",
      flexShrink: 0,
      "&:hover": {
        transform: "scale(1.1)",
        color: theme.secondary,
        backgroundColor: theme.primary,
      },
    },
    submitBtn: {
      backgroundColor: theme.primary,
      color: theme.secondary,
      transition: "250ms ease-in-out",
      "&:hover": {
        transform: "scale(1.08)",
        color: theme.secondary,
        backgroundColor: theme.buttonColor,
      },
    },
  }));

  const classes = useStyles();

  const handleContactForm = (e) => {
    e.preventDefault();
    const formData = form.current;
    const name = formData.user_name.value;
    const email = formData.user_email.value;
    const messageTitle = formData.message_title.value;
    const message = formData.message.value;
    const phoneNumber = formData.user_phone.value;
    if (name && email && message) {
      if (isEmail(email)) {
        e.preventDefault();
        setIsLoading(true);
        axios
          .post(
            "https://rputdh23z1.execute-api.us-east-1.amazonaws.com/v1/send-message",
            {
              body: JSON.stringify({
                name,
                email,
                messageTitle,
                message,
                phoneNumber,
              }),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            setSuccess(true);
            form.current.reset();
            setOpen(false);
            setIsLoading(false);
          });
      } else {
        setErrMsg("Invalid email");
        setOpen(true);
      }
    } else {
      setErrMsg("Enter all the fields");
      setOpen(true);
    }
  };

  return (
    <React.Fragment>
      <ContactUI
        open={open}
        success={success}
        errMsg={errMsg}
        handleClose={handleClose}
        classes={classes}
        handleContactForm={handleContactForm}
        form={form}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};

export default Contacts;
