export const headerData = {
  name: "Rasik Kunwar",
  title: "Hello! I am",
  desciption:
    "Experienced Full Stack Developer with a strong foundation in software design, development, and deployment. Proficient in a range of technologies including JavaScript, PHP, Laravel, Vue, React, Node, MySQL, MongoDB and more. Skilled in Agile methodologies, API integration, and enhancing application performance. Adept at collaborating with cross-functional teams to deliver efficient, user-friendly solutions. Eager to contribute expertise to innovative projects and drive technological advancement.",
  image:
    "https://rasik-portfolio.s3.us-east-1.amazonaws.com/profile.jpeg?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEO%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQCQD55H3Niq1Q0y9M0n6H6bu9Yl6%2B39hln4L7euoMG7VwIhAMKq4KpXQHqfu6icWmzqwBn9aJP3c4cpS8fQMKIoNdMaKoQDCJf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMNjEzNzkwMTY5ODI5IgwCZ5jx1oUDnMyS%2FqYq2ALMzx%2BH4Ya2doaWZY%2Fw3OJYJiMjL0ubO%2B5QYhVGD3QFec%2BhRiT9CjSqOyOBgkStS2RrWbbGxndk053Yk5Nc5oAZpLwj552tIh2rcPItuF2JuIJRImQdlzFfTvGAj%2BwH%2BeMtESAmKWqXbMmsiMPi%2BI4yKN%2Bw7ik%2FVPPSB2WCGn9INoa3vWXE9Bh5GicuHYbFvMH9ofkdDC8z%2BaxpwaZ7xN5ybf6bQXwkWCFdFuYsswzEdn8q0bbHoHH8%2BTeVrYd55CS9BmmI5Uiqi%2BYomDfY9x6p5HXOmO0pJLqbeuaSewOv801PyH02B3T0a%2FcCG%2FFLf1NKKV%2B7njeu7A5eSFzS9bsC3mgt0f1FEIC%2Fd7qlGrwXkcXypjF2XLGXQAO7FKEtWyjGMY%2BND1FBD5rmihFV9I1pYqJLPwdVVX4hLeeU5nyIZ9XVK8zRu27MNoj82VcXh%2FNywV3spDqLpTDj%2F8qmBjqyAktSpmFZuLR%2FDOPLPryfXHPApZyS2A1fqT0MujZe1JwStTA2r%2FFH0%2F1anDhnbSuEqVzqIu0IgMBZPktuHB741jRO5VGeqHnYS2YfykQq2aF4Plqv5TpyhLWiDORXyxLlmL6eRiRFZheaadUMtcAbAvD8EcUj1xC%2FDPUAtx%2FscfEiJJjWDi5pK%2Fvam%2BH5dbkgHg7%2BvkvqQWCHScUXhrOQmde8XapXO6dZuO6UDCgVYRrMu0y17QiCXP6fgnaKK0s2%2FfgIYkXXAeo5aWMrMgtfmYnOmeH2iL0EKmoPJ3VuqvARV0fCqb4S84qaOLwBgAbpsXraM0IyOnXhzj5IOH5KDLSpKpu1mEQybFcN%2F%2FGnj6%2FywH6T2S3fylc9DrsszLwgC8UBC%2BYM56CBMDuMiy1%2FOxlSRA%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230808T225905Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIAY52F6XLSUKLGAJWZ%2F20230808%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=d2f95cd72a4f1e83d08a6fbd5426205a1f444e31550ac6da79e0c962cace68ce",
  imagebw:
    "https://rasik-portfolio.s3.us-east-1.amazonaws.com/profile.jpeg?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEO%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQCQD55H3Niq1Q0y9M0n6H6bu9Yl6%2B39hln4L7euoMG7VwIhAMKq4KpXQHqfu6icWmzqwBn9aJP3c4cpS8fQMKIoNdMaKoQDCJf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMNjEzNzkwMTY5ODI5IgwCZ5jx1oUDnMyS%2FqYq2ALMzx%2BH4Ya2doaWZY%2Fw3OJYJiMjL0ubO%2B5QYhVGD3QFec%2BhRiT9CjSqOyOBgkStS2RrWbbGxndk053Yk5Nc5oAZpLwj552tIh2rcPItuF2JuIJRImQdlzFfTvGAj%2BwH%2BeMtESAmKWqXbMmsiMPi%2BI4yKN%2Bw7ik%2FVPPSB2WCGn9INoa3vWXE9Bh5GicuHYbFvMH9ofkdDC8z%2BaxpwaZ7xN5ybf6bQXwkWCFdFuYsswzEdn8q0bbHoHH8%2BTeVrYd55CS9BmmI5Uiqi%2BYomDfY9x6p5HXOmO0pJLqbeuaSewOv801PyH02B3T0a%2FcCG%2FFLf1NKKV%2B7njeu7A5eSFzS9bsC3mgt0f1FEIC%2Fd7qlGrwXkcXypjF2XLGXQAO7FKEtWyjGMY%2BND1FBD5rmihFV9I1pYqJLPwdVVX4hLeeU5nyIZ9XVK8zRu27MNoj82VcXh%2FNywV3spDqLpTDj%2F8qmBjqyAktSpmFZuLR%2FDOPLPryfXHPApZyS2A1fqT0MujZe1JwStTA2r%2FFH0%2F1anDhnbSuEqVzqIu0IgMBZPktuHB741jRO5VGeqHnYS2YfykQq2aF4Plqv5TpyhLWiDORXyxLlmL6eRiRFZheaadUMtcAbAvD8EcUj1xC%2FDPUAtx%2FscfEiJJjWDi5pK%2Fvam%2BH5dbkgHg7%2BvkvqQWCHScUXhrOQmde8XapXO6dZuO6UDCgVYRrMu0y17QiCXP6fgnaKK0s2%2FfgIYkXXAeo5aWMrMgtfmYnOmeH2iL0EKmoPJ3VuqvARV0fCqb4S84qaOLwBgAbpsXraM0IyOnXhzj5IOH5KDLSpKpu1mEQybFcN%2F%2FGnj6%2FywH6T2S3fylc9DrsszLwgC8UBC%2BYM56CBMDuMiy1%2FOxlSRA%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230808T225905Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIAY52F6XLSUKLGAJWZ%2F20230808%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=d2f95cd72a4f1e83d08a6fbd5426205a1f444e31550ac6da79e0c962cace68ce",
  resumePdf:
    "https://rasik-portfolio.s3.us-east-1.amazonaws.com/RASIK_KUNWAR_RESUME.docx?response-content-disposition=attachment&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEO%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQCQD55H3Niq1Q0y9M0n6H6bu9Yl6%2B39hln4L7euoMG7VwIhAMKq4KpXQHqfu6icWmzqwBn9aJP3c4cpS8fQMKIoNdMaKoQDCJf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMNjEzNzkwMTY5ODI5IgwCZ5jx1oUDnMyS%2FqYq2ALMzx%2BH4Ya2doaWZY%2Fw3OJYJiMjL0ubO%2B5QYhVGD3QFec%2BhRiT9CjSqOyOBgkStS2RrWbbGxndk053Yk5Nc5oAZpLwj552tIh2rcPItuF2JuIJRImQdlzFfTvGAj%2BwH%2BeMtESAmKWqXbMmsiMPi%2BI4yKN%2Bw7ik%2FVPPSB2WCGn9INoa3vWXE9Bh5GicuHYbFvMH9ofkdDC8z%2BaxpwaZ7xN5ybf6bQXwkWCFdFuYsswzEdn8q0bbHoHH8%2BTeVrYd55CS9BmmI5Uiqi%2BYomDfY9x6p5HXOmO0pJLqbeuaSewOv801PyH02B3T0a%2FcCG%2FFLf1NKKV%2B7njeu7A5eSFzS9bsC3mgt0f1FEIC%2Fd7qlGrwXkcXypjF2XLGXQAO7FKEtWyjGMY%2BND1FBD5rmihFV9I1pYqJLPwdVVX4hLeeU5nyIZ9XVK8zRu27MNoj82VcXh%2FNywV3spDqLpTDj%2F8qmBjqyAktSpmFZuLR%2FDOPLPryfXHPApZyS2A1fqT0MujZe1JwStTA2r%2FFH0%2F1anDhnbSuEqVzqIu0IgMBZPktuHB741jRO5VGeqHnYS2YfykQq2aF4Plqv5TpyhLWiDORXyxLlmL6eRiRFZheaadUMtcAbAvD8EcUj1xC%2FDPUAtx%2FscfEiJJjWDi5pK%2Fvam%2BH5dbkgHg7%2BvkvqQWCHScUXhrOQmde8XapXO6dZuO6UDCgVYRrMu0y17QiCXP6fgnaKK0s2%2FfgIYkXXAeo5aWMrMgtfmYnOmeH2iL0EKmoPJ3VuqvARV0fCqb4S84qaOLwBgAbpsXraM0IyOnXhzj5IOH5KDLSpKpu1mEQybFcN%2F%2FGnj6%2FywH6T2S3fylc9DrsszLwgC8UBC%2BYM56CBMDuMiy1%2FOxlSRA%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230808T223521Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIAY52F6XLSUKLGAJWZ%2F20230808%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=7ef6a55460e75a89dc8addbdf520c42a03f3fa1ed5f98ec2eedba5341a50e722",
};
