import { Container } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import experience from "../../../assets/lottie/development.json";
import { ThemeContext } from "../../../contexts/theme-context";
import { getExperienceData } from "../../../data/experienceData";
import AnimationLottie from "../../helper/animation-lottie";
import ExperienceCard from "./experience-card";
import "./experience.css";

function Experience() {
  const [experienceData, setExperienceData] = useState([]);
  useEffect(() => {
    getExperienceData().then((data) =>
      setExperienceData(
        data.data.body.sort((a, b) => b.startYear?.S - a.startYear?.S)
      )
    );
  }, []);

  const { theme } = useContext(ThemeContext);
  return (
    <div style={{ backgroundColor: theme.secondary }}>
      <Container className="experience" id="experience">
        <div className="experience-body">
          <div className="experience-image">
            <AnimationLottie animationPath={experience} />
          </div>
          <div className="experience-description">
            <h1 style={{ color: theme.primary }}>Experience</h1>
            {experienceData.map((exp) => (
              <ExperienceCard
                key={exp.code?.S}
                id={exp.code?.S}
                jobTitle={exp.jobTitle?.S}
                company={exp.company?.S}
                startYear={exp.startYear?.S}
                endYear={exp.endYear?.S}
              />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Experience;
