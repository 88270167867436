import { useEffect, useState } from "react";

function UseBlog() {
  const [blogs, setBlogs] = useState([]);
  const mediumUsername = "@rasikkunwar31";

  useEffect(() => {
    fetch(
      `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/${mediumUsername}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.items) {
          setBlogs(
            data.items.sort((a, b) => new Date(a.pubDate) - new Date(b.pubDate))
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching Medium blog:", error);
      });
  }, [mediumUsername]);

  return { blogs };
}

export default UseBlog;
