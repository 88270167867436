import React from "react";
import Fade from "react-reveal/Fade";
import placeholder from "../../../../assets/png/placeholder.png";
import "./single-blog.css";

function SingleBlog({ theme, title, date, image, url, id }) {
  return (
    <Fade bottom>
      <a
        className="singleBlog"
        key={id}
        href={url}
        target="_blank"
        rel="noreferrer"
        style={{ backgroundColor: theme.quaternary }}
      >
        <div
          className="singleBlog--image"
          style={{ backgroundColor: theme.secondary }}
        >
          <img src={image ? image : placeholder} alt={title} />
        </div>
        <div className="singleBlog--body">
          <p style={{ color: theme.primary }}>
            {new Date(date).toDateString()}
          </p>
          <h3 style={{ color: theme.tertiary, fontWeight: 500 }}>{title}</h3>
        </div>
      </a>
    </Fade>
  );
}

export default SingleBlog;
